import React from "react"
import Header from '../../layouts/header'
import Canvas from '../../layouts/canvas'
import StartPage from '../../layouts/startpagecontent'
import Footer from '../../layouts/footer'
import Breadcrumb from '../../layouts/breadcrumb'
import { navigate, graphql } from "gatsby"
import {CircleSpinner} from "react-spinners-kit"
import {Helmet} from 'react-helmet'








class ContactPage extends React.Component {
  constructor(props) {
    super(props)

    this.state={
      name: '',
      nameerror: '',
      email:'',

      message: '',
      messageerror:'',
      emailvalid: false,
      emailerror:'',
      buttondisabled:true,
      success:false,
      loading:false,
      errorsend: ''
    }
  }

  handleChange = (e) => {
    var $ = window.$
    var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      if(e.target.name !== 'email' && e.target.value) {
        $(`.${e.target.name}`).removeClass('notvalid')
        this.setState({
          [`${e.target.name}error`]: ""
        })
      }
      if(e.target.name === 'email' && e.target.value.match(pattern)) {
        $(`.${e.target.name}`).removeClass('notvalid')
        this.setState({
          [`${e.target.name}error`]: ""
        })
      }

      this.setState({
        [e.target.name] : e.target.value,
        buttondisabled: true
      })


      setTimeout(() => {
        if(this.state.name  && this.state.email.match(pattern) && this.state.message) {


          this.setState({
            buttondisabled:false

          })
        }
      },500)



  }

  handleBlur = (e) => {
    var $ = window.$
    if(e.target.name === 'email') {
      var pattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;


      if(!e.target.value.match(pattern)) {
         $('.email').addClass('notvalid')
         this.setState({
           emailerror:"email is required"
         })

      }
      else {
        $('.email').removeClass('notvalid')
        this.setState({
          emailvalid: true,
          emailerror:''
        })

      }
    }
    else {
      if(!e.target.value) {
        $(`.${e.target.name}`).addClass('notvalid')
        this.setState({
          [`${e.target.name}error`]: `${e.target.placeholder} is required`
        })
      }
      else {
         $(`.${e.target.name}`).removeClass('notvalid')
         this.setState({
           [`${e.target.name}error`]: ""
         })
      }
    }

  }

  handleSubmit = (e) => {
    e.preventDefault()
    this.setState({loading:true})
    console.log('Submiting')
    var baseurl = this.props.data.allSitePlugin.edges[0].node.pluginOptions.url
    var data = {
                 "webform_id":"contact",




                                "webform_data" : {

                                                "name" : this.state.name,

                                                "email" : this.state.email,



                                                "message" : this.state.message

                                }



}
    var url = `${baseurl}/wd_webform/submit`
     fetch(url, {
      method:'post',
      mode: 'cors',
      // headers: {
      //      'Content-Type': 'application/json',
      //      // 'Content-Type': 'application/x-www-form-urlencoded',
      //  },
      body:JSON.stringify(data)
    })
    .then(function(response) {


    return response.json();


})
    .then(res => {
      console.log('message sending in fetch')
      window.gtag("event", "contact_form_submit")
      this.setState({
        name: '',
        nameerror: '',
        email:'',

        message: '',
        messageerror:'',
        emailvalid: false,
        emailerror:'',
        buttondisabled:true,
        success:true,
        loading:false
      })
      setTimeout(() => {
        navigate('/')
      }, 1500)
    })
    .catch((error) => {

      window.gtag("event", "contact_form_fail")


        this.setState({
          loading:false,
          errorsend:'Network Error'
        })
        setTimeout(() => {
          navigate('/')
        }, 1500)
    })


  }







  componentWillUnmount() {
    console.log('ContactPage Unmounting')
    // document.getElementById("mainscript").remove();
  }

  componentDidUpdate() {

  }
  render() {
    var loc = []; //TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }





    })


    return (

      <Canvas>
        <Helmet>
         {this.props.data.drupal.metainfo.entities[0].entityMetatags.map((item,i) => {
           if(item.key === 'title') {
             return (
                 [<title key={i}>{item.value}</title>,<meta property="og:title" content={item.value} />]
             )
           }


           else if(item.key === 'description') {
             return (
               [ <meta key={i} name="description" content={item.value}/>, <meta property="og:description" content={item.value} />]
             )
           }

           else {
             return null;
           }
         })}
         <link  rel="canonical" href={process.env.FRONT_URL+this.props.path+'/'} />
         <meta property="og:type" content="Website" />
         <meta property="og:url" content={`${process.env.FRONT_URL}${this.props.path}`} />
         <meta property="og:image" content={`${process.env.FRONT_URL}/wd_logo.png`} />
         <meta property="og:image:width" content="200" />
         <meta property="og:image:height" content="200" />
        </Helmet>

          <Header menu={this.props.data.drupal.menuen} pathname="/contact" language="EN" langpath={[{url:'/en/contact', id:'en'}, {url:'/el/contact', id:'el'}]}/>
          <Breadcrumb title="Contact" path={loc}/>

            <section className="ls s-pt-30 s-pt-lg-75 s-pb-130 contact4">
              <div className="container">
                <div className="row c-gutter-60">

                  <div className="divider-40 d-none d-xl-block"></div>

                  <div className="col-lg-8">
                    <form className="contact-form c-mb-20 c-gutter-15">

                      <div className="row">

                        <div className="col-sm-12">
                          <h4>Contact Form</h4>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-sm-12">

                          <div className="form-group has-placeholder name">
                            <label for="name">Full Name
                              <span className="required">*</span>
                            </label>
                            <input type="text" aria-required="true" size="30" onBlur={this.handleBlur} onChange={this.handleChange} value={this.state.name} name="name" id="name" className="form-control" placeholder="name"/>

                          </div>
                          <span style={{color: 'red'}} className="errormessage">{this.state.nameerror}</span>
                        </div>


                      </div>

                      <div className="row">

                        <div className="col-sm-12">
                          <div className="form-group has-placeholder email">
                            <label for="email">Email address
                              <span className="required">*</span>
                            </label>
                            <input type="email" aria-required="true" size="30" onBlur={this.handleBlur} onChange={this.handleChange} value={this.state.email} name="email" id="email" className="form-control" placeholder="email"/>


                          </div>
                          <span style={{color: 'red'}} className="errormessage">{this.state.emailerror}</span>
                        </div>
                      </div>

                      <div className="row">

                        <div className="col-sm-12">

                          <div className="form-group has-placeholder message">
                            <label for="message">Message</label>
                            <textarea aria-required="true" rows="6" cols="45" onBlur={this.handleBlur} onChange={this.handleChange} value={this.state.message} name="message" id="message" className="form-control" placeholder="message"></textarea>

                          </div>
                          <span style={{color: 'red'}} className="errormessage">{this.state.messageerror}</span>
                        </div>

                      </div>

                      <div className="row">
                        <div className="form-group has-placeholder">
                          <button disabled={this.state.buttondisabled} onClick={this.handleSubmit} type="submit" id="contact_form_submit" name="contact_submit" className={"btn btn-block btn-maincolor"}>Send Message
                          </button>
                        </div>
                         <div>
                           <CircleSpinner
             size={30}
             color="#686769"
             loading={this.state.loading}
         />
                         </div>



                        {this.state.success? <h2 style={{color:'green'}}>Your Message has been Sent</h2> : <h2 style={{color:'red'}}>{this.state.errorsend}</h2> }
                      </div>
                    </form>
                  </div>

                  <div className="col-lg-4">
                    <div>
                      <h4 className="header">
                        Contact Info
                      </h4>

                      <div className="media mb-10">
                        <div className="icon-styled color-main fs-16">
                          <i className="fa fa-map-marker"></i>
                        </div>

                        <div className="media-body">
                          <p>
                          Megalou Alexandrou 91, 18120 Korydallos

                          </p>
                        </div>
                      </div>

                      <div className="media mb-10">
                        <div className="icon-styled color-main fs-16">
                          <i className="fa fa-phone"></i>
                        </div>

                        <div className="media-body">
                          <p>
                           <a href="tel:+302107703051">+30 210 770 3051</a>

                          </p>
                        </div>
                      </div>

                      <div className="media mb-40">
                        <div className="icon-styled color-main fs-16">
                          <i className="fa fa-pencil"></i>
                        </div>

                        <div className="media-body">
                          <p className="color-dark">
                          <a href="mailto:hello@webdimension.gr">hello@webdimension.gr</a>

                          </p>
                        </div>
                      </div>

                      <h4>Open Hours</h4>


                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <div className="icon-styled color-main fs-16">
                                <i className="fa fa-clock-o"></i>
                              </div>
                              Weekdays
                            </td>
                            <td className="color-dark">10:00-18:00</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="icon-styled color-main fs-16">
                                <i className="fa fa-clock-o"></i>
                              </div>
                              Saturday
                            </td>
                            <td className="color-dark">Closed</td>
                          </tr>
                          <tr>
                            <td>
                              <div className="icon-styled color-main fs-16">
                                <i className="fa fa-clock-o"></i>
                              </div>
                              Sunday
                            </td>
                            <td className="color-dark">Closed</td>
                          </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="divider-60 d-none d-xl-block"></div>
                </div>
              </div>
            </section>

            <section className="mapcontainer">
              <div className="mapouter"><div className="gmap_canvas"><iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=web%20dimension&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.embedgooglemap.org"></a></div>
            </div>
            </section>





          <Footer/>

      </Canvas>



    )
  }

}





export default ContactPage;

export const query = graphql `
{
  allSitePlugin(filter: {name: {eq: "gatsby-source-graphql"}}) {
edges {
node {
  pluginOptions {
    url
  }
  name
}
}
}
  drupal {
  menuen: menuByName(name: "main", language: EN) {
  links {
    label
    url {
      path
    }
  }
}

metainfo:nodeQuery(filter: {conditions: [{field: "title", value: "contact"}]}) {
     entities(language: EN) {
       ... on DRUPAL_NodePage {
         entityLabel
         entityMetatags {
           key
           value
         }
       }
     }
   }
}
}
`
